 
#nav{
    background-color: #999D9E;
    font-size: 1.3rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 45px;
    height: 10vh;
    /* background-color: rgb(182, 182, 255); */
}

#nav a{
    font-weight: 400;
    color: #fff;
}

 

#rightnav{
    width: 30%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

a{
    text-decoration: none;
}
#leftnav{ 
    text-overflow:ellipsis;
}
#work{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#about{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: aquamarine; */
}

#contact{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scir{
    width: 7px;
    height: 7px;
    background-color: #fff;
    border-radius: 50%;
    margin-top: 5px;
    display: none;
}