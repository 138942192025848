 

section{
    min-height: 100vh;
    padding-top: 8rem;
}

.home{
    background-color: #999D9E; 
    color: white;
} 


#mainelements{
    position: absolute;
    top: 10%;
    width: 100%;
    height: 100vh; 
}

#leftglobe{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px;
    width: 20vw;
    height: 15vh;
    border-radius: 0px 50px 50px 0px;
    background-color: black;
    color:  white;
    position: absolute;
    top: 35%;
    transform: translate(0%, -50%);
}

#leftglobe h1{
    margin-left: 25px;
    font-size: 1.5vw;
}

#globec{
    width: 6vw;
    height: 6vw;
    background-color: #999D9E;
    border-radius: 50%;
    position: relative;
    animation: circle2 2.7s linear infinite;
}

#globec .circle{
    position: absolute;
    width: 95%;
    height: 95%;
    border-radius: 50%;
    box-shadow: inset 0.1em 0px 0px 0.08em white;
    font-size: .75em;
}



#globec .circle-hor {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    height: 40%;
    box-shadow: inset 0px 0px 0px 0.15em white;
    font-size: .75em;
}


#globec .circle-hor-middle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40%;
    transform: translate(-50%, -50%);
    border-radius: 0%;
    height: 0.15em;
    background: white;
    font-size: .75em;
}


#freelance{
    position: absolute;
    top: 20%;
    right: 5%;
}

#freelance i{
    display: inline-block;
    font-size: 3vw;
    margin-bottom: 35px;
}

#freelance h1{
    font-size: 2.5vw;
    font-weight: 400;
}

#name{
    width: 400%;
    position: absolute;
    top: 50%;
}

#name h1{
    display: inline-block;
    font-size: 12vw;
    font-weight: 400;
    animation: 10s linear infinite;
    animation-name: text;
}
@keyframes circle2{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
} 

@keyframes text{
    from{
        transform: translate(0%);
    }
    to{
        transform: translate(-50.5%);
    }
} 