@import url('https://fonts.googleapis.com/css2?family=Aboreto&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Silkscreen&display=swap');


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
}

:root{
  --bg-color: #fdfdfd;
  --text-color: #333;
  --main-color: #542fcd;
  --white-color: #fdfdfd;
  --shadow-color: rgba(0, 0, 0, .2);

    /* --yellow: #F5C32C;
    --orange : #FCA61F;
    --black : #242D49;
    --gray : #788097;
    --white : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08); */

}

html{
  font-size: 62.5%;
  overflow-x: hidden;

}

body{
  background-color: var(--white);
  color: var(--black);
}

#hello {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5rem;
    opacity: 1;
    transition: opacity 1s ease-out;
    background-color: black;
    color: white;
    z-index: 1000;
  }
  