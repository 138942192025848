#four{
    position: relative;
    width: 100%;
    height: 50vh;
    background-color: #080808;
}

#ficon{
    position: absolute;
    top: 15%;
    left: 2%;
    display: flex;
}

#lfticon{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    /* background-image: url(/images/last.jpg); */
    background-size: cover;
    background-position: center;
}

#ficon h2{
    margin-left: 0px;
    font-size: 6vw;
    font-weight: 400;
    color:  white;
}

#ficon h1 span{
    margin-left: -90px;
}

#fline{
    position: absolute;
    top: 50%;
}

#getintouch{
    position: absolute;
    top: 45%;
    right: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12vw;
    height: 12vw;
    background-color: blue;
    border-radius: 50%;
}

#getintouch h1{
    font-size: 1.5vw;
    font-weight: 400;
    color: white;
}

#buttons{
    position: absolute;
    top: 70%;
    left: 10%;
    display: flex;
    font-size: 1.2rem;
}

#fleftbtn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25vw;
    height: 5vw;
    border-radius: 50px;
    /* background-color: #fff; */
    border: 1px solid #fff;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
}

#fleftbtn:hover{
    cursor: pointer;
    background-color: blue;
    border: transparent;
}

#fleftbtn a{
    color: #fff;
}

#frightbtn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20vw;
    height: 5vw;
    margin-left: 20px;
    border-radius: 50px;
    /* background-color: #fff; */
    border: 1px solid #fff;
}

#frightbtn:hover{
    cursor: pointer;
    background-color: blue;
    border: transparent;
}

#frightbtn a{
    color: #fff;
}

 
 
 
 

